<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="안전보건 경영방침 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable && popupParam.policyId" label="삭제" icon="delete_forever" @btnClicked="removeData" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  required
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  required
                  type="year"
                  :editable="editable"
                  label="게시년도"
                  name="policyYear"
                  v-model="data.policyYear">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  required
                  :editable="editable"
                  label="제목"
                  name="policyTitle"
                  v-model="data.policyTitle">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  required
                  :editable="editable"
                  label="게시일"
                  name="policyDate"
                  v-model="data.policyDate">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-textarea
                  :editable="editable"
                  type="editor"
                  label="안전보건 경영방침"
                  name="policyContents"
                  :editheight="40"
                  :maxHeight="40 + 'rem'"
                  v-model="data.policyContents">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-upload 
                  :fullWidth="false"
                  :attachInfo="attachInfo"
                  :editable="editable"
                  label="관련자료">
                </c-upload>
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-table
                  ref="grid"
                  title="안전보건 경영방침 항목"
                  tableId="grid"
                  :columnSetting="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :filtering="false"
                  :columns="grid.columns"
                  :isExcelDown="false"
                  :data="data.itemList"
                  gridHeight="600px"
                  selection="multiple"
                  rowKey="policyItemId"
                  :editable="editable"
                >
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn label="추가" icon="add" @btnClicked="addrow" v-if="editable" />
                      <c-btn label="삭제" icon="remove" @btnClicked="removeRow" v-if="editable&&data.itemList.length>0" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div> -->
            </template>
          </c-card>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'saf-hea-manage-policy-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        policyId: '',
      }),
    },
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'SHM_POLICY',
        taskKey: '',
        beforeTaskKey: '',
      },
      saveUrl: transactionConfig.sai.shm.policy.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      regUserName: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      insertLogUrl: '',
      deleteItemUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      data: {
        policyId: '',
        plantCd: '',
        policyDate: '',
        policyYear: '',
        policyContents: '',
        policyTitle: '',
          
        policyLogModelList: [],
        itemList: [],

        regUserId: '',
      },
      grid: {
        columns: [
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            align: 'center',
            style: 'width:70px',
            type: 'number',
            sortable: false,
          },
          {
            name: 'item',
            field: 'item',
            label: '항목',
            align: 'left',
            type: 'textarea',
            sortable: false,
          },
        ],
        data: [],
      },
      updateMode: false,
      answerMode: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.data.policyDate = this.$comm.getToday();
      
      this.detailUrl = selectConfig.sai.shm.policy.get.url;
      this.insertUrl = transactionConfig.sai.shm.policy.insert.url;
      this.updateUrl = transactionConfig.sai.shm.policy.update.url;
      this.deleteUrl = transactionConfig.sai.shm.policy.delete.url;
      this.insertLogUrl = transactionConfig.sai.shm.policy.insertlog.url;
      this.deleteItemUrl = transactionConfig.sai.shm.policy.itemdelete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.policyId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.policyId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;

          this.$set(this.attachInfo, 'taskKey', this.popupParam.policyId);
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo',this.data)

          this.$http.url = this.insertLogUrl;
          this.$http.type = 'POST';
          this.$http.param = {
            regUserId: this.$store.getters.user.userId,
            policyLogId: '',
            policyId: this.popupParam.policyId
          }
          this.$http.request(() => {
          },);
        },);
      }
    },
    saveData() {
      if (this.popupParam.policyId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인', 
            message: '저장하시겠습니까?',
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;

              this.data.itemList = this.data.itemList.filter( x => {
                return x.editFlag == 'C' || x.editFlag == 'U'
              });
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',  
        message: '삭제하시겠습니까?',
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.policyId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.policyId = result.data
      this.$set(this.attachInfo, 'taskKey', this.popupParam.policyId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.getDetail();
    },
    addrow() {
      let sortorder = this.data.itemList.length + 1;
      this.data.itemList.push({
        editFlag: 'C',
        policyItemId: uid(),  // 경영방침 항목 일련번호
        policyId: this.data.policyId,  // 경영방침 일련번호
        sortOrder: sortorder,  // 순번
        item: '',  // 항목
      })
    },
    removeRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인', 
          message: '삭제하시겠습니까?', 
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteItemUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.data.itemList = this.$_.reject(this.data.itemList, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    }
  }
};
</script>
